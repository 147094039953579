import { RestClientService } from './RestClientService';
require('dotenv').config();

class PromptsService {

  constructor() {
    this.state = {
      restClient: RestClientService.getAiBridgeRestClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  // -- ADMINISTRATIVE METHODS - Auth required -------------------------------------------------------------------------------

  async fetchPrompts(page, rowsPerPage, sortBy, sortDirection, keywords) {
    return this.getRestClient().get('/prompts/v1.0/?from=' + (page*rowsPerPage) + '&size=' + rowsPerPage + '&sort=' + sortBy+ '&order=' + sortDirection + '&keywords=' + encodeURIComponent(keywords));
  }

  async fetchAllPromptsByType(ofType) {
    return this.getRestClient().get('/prompts/v1.0/?from=0&size=100&type=' + encodeURIComponent(ofType));
  }

  async fetchAllPromptsByTypeAndLang(ofType, lang) {
    return this.getRestClient().get('/prompts/v1.0/?from=0&size=100&type=' + encodeURIComponent(ofType) + '&lang=' + lang);
  }

  async deletePrompt(promptId) {
    return this.getRestClient().delete(`/prompts/v1.0/${promptId}`);
  }

  async fetchPrompt(promptId) {
      return this.getRestClient().get(`/prompts/v1.0/${promptId}`);
  }

  async savePrompt(promptId, prompt) {
    if(promptId > 0) {
      // existing prompt
      return this.getRestClient().put(`/prompts/v1.0/${promptId}`, prompt);
    } else {
      // new prompt
      return this.getRestClient().post('/prompts/v1.0/', prompt);
    }
  }

  async askSimpleQuestion(lang, question) {
    const data = {lang: lang, question: question};
    return this.getRestClient().post('/questions/v1.0/simple', data);
  }
}

export default PromptsService;
