import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import ListItem from '@mui/material/ListItem';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { SurveyContext } from './context';
import { cloneDeep, indexOf, isBlank, stripHtmlTags } from '../../utils/utils';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Divider, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ListItemButton from '@mui/material/ListItemButton';
import LockIcon from '@mui/icons-material/Lock';

const useStyles = makeStyles()(theme => ({
    dialog: {
        marginTop: 80,
    },
    styleDialogContent: {
        height: 900, 
        width: 900, 
        position: 'relative' 
    },
    relative: {
        position: 'relative'
    },
    marginTop7: {
        marginTop: 7
    },
    expandIcon: {
        marginLeft: 10, 
        marginTop: 3, 
        color: 'grey'
    },
    listItem: {
        justifyContent: 'space-between',
        paddingLeft: 5,
        maxHeight: 45
    },
    flex: {
        display: 'flex'
    },
    textMultipleQuestion: {
        marginTop: 7, 
        fontStyle: 'italic', 
        color: 'grey'
    },
    flexMarginLeft: {
        display: 'flex',
        marginLeft: 20
    },
    marginTop12: {
        marginTop: 12
    },
    mandatory: {
        marginLeft: 'auto', 
        marginRight: 20, 
        marginTop: 10, 
        color: '#c30d0d'
    },
    textConditions: {
        marginTop: 0, 
        marginLeft: 600, 
        width: 220, 
        position: 'fixed'
    },
    helperText: {
        fontStyle: "italic",
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 20,
        color: 'grey'
    },
    selectCondition: {
        marginBottom: 15,
        minWidth: 300
    },
    arrowForwardIosIcon: {
        verticalAlign: 'middle',
        paddingRight: 10
    },
    textHelperUnavailable: {
        fontStyle: 'italic',
        marginLeft: 10,
        marginTop: 5
    },
    greyColorIcon: {
        color: 'rgb(200,200,200)',
    },
    flexBetweenMinAnswers: {
        display: 'flex',
        marginBottom: 5, 
    },
    labelSwitchQuestion: {
        marginTop: 10,
    },
    textFieldMinMaxAnswers: {
        width: 40,
        marginLeft: 15,
        marginTop: 5
    }, 
    divSelectMinMaxAnswers: {
        marginLeft: 20,
        marginBottom: 15
    }
}));

const CustomCheckbox = withStyles(
    (props) => <Checkbox color="default" {...props} />,
    (_theme, _params, classes) => ({
        root: {
            color: '#c30d0d',
            [`&.${classes.checked}`]: {
                color: '#c30d0d'
            },
        },
        checked: {},
    })
);

export default function PopupConditions(props) {

    const { classes } = useStyles();

    const { 
        t, 
        selectedIndex, 
        currentBlock,
        openPopupConditions,
        editCondition,
        conditionners,
        renderConditionner,
        callBackConfirmConditions,
        callBackCancelConditions,
        getQuestionIndexByBlockUuid,
        conditionService
     } = props;

    const { getAllBlocks, computeTitle, getFormConfiguration } = useContext(SurveyContext);

    const [currentCondition, setCurrentCondition] = useState(undefined);
    const [conditionName, setConditionName] = useState('');
    const [openCollapse, setOpenCollapse] = useState([]);
    const [conditionedUuid, setConditionedUuid] = useState(undefined);
    const [selectedExisting, setSelectedExisting] = useState('create-new');
    const [usableConditions, setUsableConditions] = useState([]);

    useEffect(() => {
        if(!openPopupConditions) return;

        let oc = [];
        let cu = undefined;
        let cbc = undefined;

        if(editCondition.uuid !== undefined) {
            let originalCondition = conditionners.find(c => c.uuid === editCondition.uuid);

            // clone to avoid modify the original during the display
            cbc = cloneDeep(originalCondition);

            // look at all block to find those to open
            // oc contains index of Block to open/close
            oc = getIndexOfPreviousBlocksToExpend(originalCondition);
            cu = editCondition.conditionedUuid;
        } else {
            cbc = conditionService.createConditionner();
            cu = currentBlock.uuid;
        }

        let uc = getUsableConditionners(conditionners)
        setUsableConditions(uc);

        setOpenCollapse(oc);
        setConditionedUuid(cu);
        setConditionName(cbc.name);
        setCurrentCondition(cbc);
        setSelectedExisting("create-new");
    }, [openPopupConditions])

    /**
     * we look at all available conditionners to check if they have a rule with a question that is AFTER what we display (the currentBlock)
     * we will keep ONLY rules with questionUuid BEFORE us.
     * 
     * @param {*} listOfConditionners 
     * @returns 
     */
    const getUsableConditionners = (listOfConditionners) => {
        const myPosition = indexOf(getAllBlocks(), 'uuid', currentBlock.uuid);

        return listOfConditionners
            .filter(cdtn => {
                let parentUuids = conditionService.getConcernedParentUuids(cdtn);

                const referToMeOrAfter = getAllBlocks()
                    .map((b, bidx) => parentUuids.includes(b.configuration.uuid) ? bidx : -1)
                    // keep only idx >= 0
                    .filter(bidx => bidx >= 0 && bidx >= myPosition)
                    .length > 0;

                return !referToMeOrAfter > 0 && !isBlank(cdtn.name);
            })
            .sort((a, b) => a.name.localeCompare(b.name));
    };

    // -- Profile questions functions --------------------------------------------------------------------------

    const renderQuestion = (block, blockIdx) => {
        const question = block.configuration.question;
        const isUnavailable = isBlockUnavailableForCondition(block);

        return (
            <div key={blockIdx}>
                <ListItem className={classes.listItem}>
                    <ListItemButton className={classes.listItem} onClick={isUnavailable ? () => {} : () => actionCollapse(block.uuid)}>
                        Q{getQuestionIndexByBlockUuid(block.uuid)} - {computeTitle(block)}
                        {isUnavailable && 
                            <Tooltip title={t('react.surveys.block.belongs.to.randomized.group')}>
                                <LockIcon fontSize='small' className={classes.expandIcon}/>
                            </Tooltip>
                        }
                        {!isUnavailable && 
                            <>{openCollapse.includes(block.uuid) ? <ExpandLessIcon className={classes.expandIcon}/> : <ExpandMoreIcon className={classes.expandIcon}/>}</>
                        }
                    </ListItemButton>
                </ListItem>
                <Collapse in={openCollapse.includes(block.uuid)} timeout="auto" unmountOnExit>
                    <div className={classes.helperText}>{question.type === "single" ? t('react.survey.conditions.helper.question.single') : t('react.survey.conditions.helper.question.multiple')}</div>
                    {question.answers.map((answer, indexAnswer) => (
                        <div className={classes.flexMarginLeft} key={indexAnswer}>
                            <span className={classes.marginTop12}>A{indexAnswer + 1}</span>
                            {question.type === "multiple" &&
                                <CustomCheckbox
                                    checked={isChecked(block, answer, 2)}
                                    onChange={(event) => onChangeCheckboxOfQuestionAnswer(event, block, answer, 2)}
                                />
                            }
                            <Checkbox
                                checked={isChecked(block, answer, 1)}
                                onChange={(event) => onChangeCheckboxOfQuestionAnswer(event, block, answer, 1)}
                            />
                            <Checkbox
                                checked={isChecked(block, answer, 0)}
                                onChange={(event) => onChangeCheckboxOfQuestionAnswer(event, block, answer, 0)}
                                checkedIcon={<CancelIcon />}
                            />
                            <div className={classes.marginTop12}>{answer.answer}</div>
                            {isChecked(answer, 2) &&
                                <div className={classes.mandatory}>{t('react.survey.conditions.answer.mandatory')}</div>
                            }
                        </div>
                    ))}
                    {renderMinMaxFields(block)}
                </Collapse>
            </div>    
        );
    };

    const onChangeCheckboxOfQuestionAnswer = (event, block, answer, type) => {
        if(event.target.checked) {
            let newCurrentCondition = cloneDeep(currentCondition)
            conditionService.addQuestionAnswerWithType(newCurrentCondition, block.uuid, answer.uuid, type);
            setCurrentCondition(newCurrentCondition);
        } else {
            let newCurrentCondition = cloneDeep(currentCondition)
            conditionService.removeQuestionAnswer(newCurrentCondition, block.uuid, answer.uuid);
            setCurrentCondition(newCurrentCondition);
        }
    };

    const isChecked = (block, answer, type) => {
        return conditionService
            .isQuestionAnswerInConditionnerWithType(currentCondition, block.uuid, answer.uuid, type);
    };

    // -- Hotspots functions --------------------------------------------------------------------------

    const renderHotspot = (block, blockIdx) => {
        return block.configuration.hotspot.zones.map((zone, zoneIdx) => {
            return renderHotspotZone(block, blockIdx, zone, zoneIdx);
        });
    };

    const renderHotspotZone = (block, blockIdx, zone, zoneIdx) => {
        const hotspot = block.configuration.hotspot;
        const isUnavailable = isBlockUnavailableForCondition(block);

        return (
            <div key={`${blockIdx}-${zoneIdx}`}>
                <ListItem className={classes.listItem}>
                    <ListItemButton className={classes.listItem} onClick={isUnavailable ? () => {} : () => actionCollapse(zone.uuid)}>
                        Q{getQuestionIndexByBlockUuid(block.uuid)}.Z{zoneIdx+1} - {computeTitle(block)} - {stripHtmlTags(zone.name)}
                        {isUnavailable && 
                            <Tooltip title={t('react.surveys.block.belongs.to.randomized.group')}>
                                <LockIcon fontSize='small' className={classes.expandIcon}/>
                            </Tooltip>
                        }
                        {!isUnavailable && 
                            <>{openCollapse.includes(zone.uuid) ? <ExpandLessIcon className={classes.expandIcon}/> : <ExpandMoreIcon className={classes.expandIcon}/>}</>
                        }
                    </ListItemButton>
                </ListItem>
                <Collapse in={openCollapse.includes(zone.uuid)} timeout="auto" unmountOnExit>
                    <div className={classes.helperText}>{t('react.survey.conditions.helper.question.single')}</div>
                        {hotspot.answers.map((answer, answerIdx) => (
                        <div className={classes.flexMarginLeft} key={answerIdx}>
                            <span className={classes.marginTop12}>A{answerIdx + 1}</span>
                            <Checkbox
                                checked={isHotspotZoneAnswerChecked(block, zone, answer, 1)}
                                onChange={(event) => onChangeHotspotZoneAnswer(event, block, zone, answer, 1)}
                            />
                            <Checkbox
                                checked={isHotspotZoneAnswerChecked(block, zone, answer, 0)}
                                onChange={(event) => onChangeHotspotZoneAnswer(event, block, zone, answer, 0)}
                                checkedIcon={<CancelIcon />}
                            />
                            <div className={classes.marginTop12}>{answer.name}</div>
                        </div>
                    ))}
                    {renderMinMaxFields(block, zone)}
                </Collapse>
            </div>    
        );
    };

    const onChangeHotspotZoneAnswer = (event, block, zone, answer, type) => {
        if(event.target.checked) {
            let newCurrentCondition = cloneDeep(currentCondition)
            conditionService.addHotspotZoneAnswerWithType(newCurrentCondition, zone.uuid, answer.uuid, type);
            setCurrentCondition(newCurrentCondition);
        } else {
            let newCurrentCondition = cloneDeep(currentCondition)
            conditionService.removeHotspotZoneAnswer(newCurrentCondition, zone.uuid, answer.uuid);
            setCurrentCondition(newCurrentCondition);
        }
    };

    const isHotspotZoneAnswerChecked = (block, zone, answer, type) => {
        return conditionService
            .isHotspotZoneAnswerInConditionnerWithType(currentCondition, zone.uuid, answer.uuid, type);
    };

    // -- Battery functions --------------------------------------------------------------------------

    const renderBattery = (block, blockIdx) => {
        return block.configuration.battery.items.map((item, itemIdx) => {
            return renderBatteryItem(block, blockIdx, item, itemIdx);
        });
    };

    const renderBatteryItem = (block, blockIdx, item, itemIdx) => {
        const battery = block.configuration.battery;
        const isUnavailable = isBlockUnavailableForCondition(block);

        return (
            <div key={`${blockIdx}-${itemIdx}`}>
                <ListItem className={classes.listItem}>
                    <ListItemButton className={classes.listItem} onClick={isUnavailable ? () => {} : () => actionCollapse(item.uuid)}>
                        Q{getQuestionIndexByBlockUuid(block.uuid)}.I{itemIdx+1} - {computeTitle(block)} - {stripHtmlTags(item.text)}
                        {isUnavailable && 
                            <Tooltip title={t('react.surveys.block.belongs.to.randomized.group')}>
                                <LockIcon fontSize='small' className={classes.expandIcon}/>
                            </Tooltip>
                        }
                        {!isUnavailable && 
                            <>{openCollapse.includes(item.uuid) ? <ExpandLessIcon className={classes.expandIcon}/> : <ExpandMoreIcon className={classes.expandIcon}/>}</>
                        }
                    </ListItemButton>
                </ListItem>
                <Collapse in={openCollapse.includes(item.uuid)} timeout="auto" unmountOnExit>
                    <div className={classes.helperText}>{battery.type === 'single' ? t('react.survey.conditions.helper.question.single') : t('react.survey.conditions.helper.question.multiple')}</div>
                    {battery.answers.map((answer, answerIdx) => (
                        <div className={classes.flexMarginLeft} key={answerIdx}>
                            <span className={classes.marginTop12}>A{answerIdx + 1}</span>
                            {battery.type === "multiple" &&
                                <CustomCheckbox
                                    checked={isBatteryAnswerItemChecked(block, item, answer, 2)}
                                    onChange={(event) => onChangeBatteryItemAnswer(event, block, item, answer, 2)}
                                />
                            }
                            <Checkbox
                                checked={isBatteryAnswerItemChecked(block, item, answer, 1)}
                                onChange={(event) => onChangeBatteryItemAnswer(event, block, item, answer, 1)}
                            />
                            <Checkbox
                                checked={isBatteryAnswerItemChecked(block, item, answer, 0)}
                                onChange={(event) => onChangeBatteryItemAnswer(event, block, item, answer, 0)}
                                checkedIcon={<CancelIcon />}
                            />
                            <div className={classes.marginTop12}>{answer.text}</div>
                        </div>
                    ))}
                    {renderMinMaxFields(block, item)}
                </Collapse>
            </div>    
        );
    };

    const onChangeBatteryItemAnswer = (event, block, item, answer, type) => {
        if(event.target.checked) {
            let newCurrentCondition = cloneDeep(currentCondition)
            conditionService.addBatteryItemAnswerWithType(newCurrentCondition, item.uuid, answer.uuid, type);
            setCurrentCondition(newCurrentCondition);
        } else {
            let newCurrentCondition = cloneDeep(currentCondition)
            conditionService.removeBatteryItemAnswer(newCurrentCondition, item.uuid, answer.uuid);
            setCurrentCondition(newCurrentCondition);
        }
    };

    const isBatteryAnswerItemChecked = (block, item, answer, type) => {
        return conditionService
            .isBatteryItemAnswerInConditionnerWithType(currentCondition, item.uuid, answer.uuid, type);
    };

    // -- Min / Max functions ------------------------------------------------------------------------

    const setMinOrMaxMatchesFieldValue = (minOrMaxField, value, block, itemOrZone = undefined) => {
        // check the value vs the number of selected elements
        const nbSelectedElements = getNbSelectedAnswers(block, itemOrZone);
        if(value < 1 || value > nbSelectedElements) return;

        if(minOrMaxField === 'nbMinMatches') {
            const otherFieldValue = getMinOrMaxMatchesFieldValue('nbMaxMatches', block, itemOrZone);
            // min value cannot be greater than max
            if(value > otherFieldValue) return;
        } else if (minOrMaxField === 'nbMaxMatches') {
            const otherFieldValue = getMinOrMaxMatchesFieldValue('nbMinMatches', block, itemOrZone);
            // max value cannot be less than max
            if(value < otherFieldValue) return;
        }

        const parentUuid = itemOrZone ? itemOrZone.uuid : block.uuid;

        let newCurrentCondition = cloneDeep(currentCondition);
        conditionService.setMinOrMaxMatchesFieldValue(newCurrentCondition, parentUuid, minOrMaxField, value);
        setCurrentCondition(newCurrentCondition);
    };

    const getMinOrMaxMatchesFieldValue = (minOrMaxField, block, itemOrZone = undefined) => {
        const parentUuid = itemOrZone ? itemOrZone.uuid : block.uuid;
        const condition = currentCondition.conditions
            .find(c => c.parentUuid === parentUuid)?.groups
                .find(g => g.joiner === "OR");

        return condition?.[minOrMaxField] ? condition[minOrMaxField] : 1;
    };

    const getNbSelectedAnswers = (block, itemOrZone = undefined) => {
        const parentUuid = itemOrZone ? itemOrZone.uuid : block.uuid;
        const condition = currentCondition.conditions
            .find(c => c.parentUuid === parentUuid)?.groups
                .find(g => g.joiner === "OR");

        return condition?.elements ? condition.elements.length : 0;
    };

    const renderMinMaxFields = (block, type = undefined) => {
        return (<div className={classes.divSelectMinMaxAnswers}>
            <div className={classes.flexBetweenMinAnswers} >
                <div className={classes.labelSwitchQuestion}>{t('react.project.collectforms.minimum.answers.number')}</div>
                <TextField
                    value={getMinOrMaxMatchesFieldValue("nbMinMatches", block, type)}
                    onChange={(e) => setMinOrMaxMatchesFieldValue("nbMinMatches", parseInt(e.target.value), block, type)}
                    disabled={getNbSelectedAnswers(block, type) < 2}
                    type='number'
                    className={classes.textFieldMinMaxAnswers}
                />
            </div>
            <div className={classes.flexBetweenMinAnswers} >
                <div className={classes.labelSwitchQuestion}>{t('react.surveys.maximum.answers.number')}</div>
                <TextField
                    value={getMinOrMaxMatchesFieldValue("nbMaxMatches", block, type)}
                    onChange={(e) => setMinOrMaxMatchesFieldValue("nbMaxMatches", parseInt(e.target.value), block, type)}
                    disabled={getNbSelectedAnswers(block, type) < 2}
                    type='number'
                    className={classes.textFieldMinMaxAnswers}
                />
            </div>
        </div>)
    }

    // -- Generic functions --------------------------------------------------------------------------

    const actionCollapse = (uuid) => {
        let tempArr = [...openCollapse];
        if (tempArr.includes(uuid)) {
            setOpenCollapse(tempArr.filter(value => value !== uuid));
        } else {
            tempArr.push(uuid);
            setOpenCollapse(tempArr);
        }
    };

    const handleConfirm = () => {
        callBackConfirmConditions(currentCondition, conditionName, conditionedUuid);
    };

    const handleChangeConditionedUuid = (event) => {
        setConditionedUuid(event.target.value);
    };

    const onChangeSelectedExisting = (selectedConditionUuid) => {
        setSelectedExisting(selectedConditionUuid);

        let oc = [];
        let cbc = undefined;

        if(selectedConditionUuid !== 'create-new') {
            let originalCondition = conditionners.find(c => c.uuid === selectedConditionUuid);

            // clone to avoid modify the original during the display
            cbc = cloneDeep(originalCondition);
    
            // // look at all block to find those to open
            oc = getIndexOfPreviousBlocksToExpend(originalCondition);
        } else {
            cbc = conditionService.createConditionner();
        }

        setOpenCollapse(oc);
        setConditionName(cbc.name);
        setCurrentCondition(cbc);
    };

    const getIndexOfPreviousBlocksToExpend = (ofConditionner) => {
        return conditionService.getConcernedParentUuids(ofConditionner);
    };

    const isBlockUnavailableForCondition = (block) => {
        // If a block belongs to a group with shuffleBlocks set to true, then we can't select that block
        return currentBlock.configuration.groupId == block.configuration.groupId && getFormConfiguration().groups
            .some(g => g.id === block.configuration.groupId && g.shuffleBlocks);
    };

    if(!openPopupConditions || !currentCondition) return null;
   
    return (
        <Dialog
            className={classes.dialog}
            maxWidth='lg'
            open={openPopupConditions}
            onClose={callBackCancelConditions}
        >
            <DialogTitleWithCloseIcon
                title={t('react.survey.conditions.popupcondition.title', {blockName: computeTitle(currentBlock)})}
                callbackOnclose={callBackCancelConditions}
            />
            <DialogContent className={classes.styleDialogContent}>
                <Grid container className={classes.relative}>
                    {editCondition.uuid === undefined && usableConditions.length > 0 && 
                    <Grid item xs={8}>
                        <Typography>{t("react.survey.conditions.popupcondition.select.title")}</Typography>
                        <ArrowForwardIosIcon className={classes.arrowForwardIosIcon}/>
                        <Select
                            value={selectedExisting}
                            className={classes.selectCondition}
                            onChange={e => onChangeSelectedExisting(e.target.value)}
                        >
                            <MenuItem value='create-new'>{t("react.survey.conditions.popupcondition.select.value")}</MenuItem>
                            <Divider></Divider>
                            {usableConditions.map(cdtn => (
                            <MenuItem key={cdtn.uuid} value={cdtn.uuid}>{cdtn.name}</MenuItem>
                            ))}
                        </Select>
                    </Grid>}
                    <Grid item xs={8}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={t("react.survey.conditions.popupcondition.edit.name")}
                            type="text"
                            value={conditionName}
                            autoComplete="off"
                            onChange={e => setConditionName(e.target.value)}
                            className={classes.textField}
                            name="name"
                            helperText={t("react.survey.conditions.popupcondition.edit.helperText")}
                        />
                        {getAllBlocks().filter((block, blockIdx) => selectedIndex > blockIdx).map((block, blockIdx) => {
                            if (block.type === 'question') {
                                return renderQuestion(block, blockIdx)
                            } else if (block.type === 'hotspot') {
                                return renderHotspot(block, blockIdx)
                            } else if (block.type === 'battery') {
                                return renderBattery(block, blockIdx)
                            }
                        })}
                    </Grid>
                    <div className={classes.textConditions}>
                        {currentBlock.type === 'question' && currentBlock.configuration.question.answers.length > 0 &&
                            <FormControl style={{width: '100%', marginTop: 4.5, marginBottom: 30}} disabled={editCondition.uuid !== undefined}>
                                <TextField
                                    select
                                    label={t('react.survey.conditions.popupcondition.apply.to')}
                                    value={conditionedUuid}
                                    onChange={handleChangeConditionedUuid}
                                >
                                    <MenuItem value={currentBlock.uuid}>{t('react.survey.conditions.popupcondition.apply.to.block.display')}</MenuItem>
                                    <MenuItem disabled={true}><Divider style={{width: '100%'}}/></MenuItem>
                                    <MenuItem disabled={true}>{t('react.survey.conditions.popupcondition.apply.to.answer.display')}</MenuItem>
                                    {currentBlock.configuration.question.answers.map((a, index) => (
                                        <MenuItem value={a.uuid} key={index}>{stripHtmlTags(a.answer)}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        }
                        {currentBlock.type === 'hotspot' && currentBlock.configuration.hotspot.zones.length > 0 &&
                            <FormControl style={{width: '100%', marginTop: 4.5, marginBottom: 30}} disabled={editCondition.uuid !== undefined}>
                                <TextField
                                    select
                                    label={t('react.survey.conditions.popupcondition.apply.to')}
                                    value={conditionedUuid}
                                    onChange={handleChangeConditionedUuid}
                                >
                                    <MenuItem value={currentBlock.uuid}>{t('react.survey.conditions.popupcondition.apply.to.block.display')}</MenuItem>
                                    <MenuItem disabled={true}><Divider style={{width: '100%'}}/></MenuItem>
                                    <MenuItem disabled={true}>{t('react.survey.conditions.popupcondition.apply.to.zone.display')}</MenuItem>
                                    {currentBlock.configuration.hotspot.zones.map((z, index) => (
                                        <MenuItem value={z.uuid} key={index}>{stripHtmlTags(z.name)}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        }
                        {currentBlock.type === 'battery' && currentBlock.configuration.battery.items.length > 0 &&
                            <FormControl style={{width: '100%', marginTop: 4.5, marginBottom: 30}} disabled={editCondition.uuid !== undefined}>
                                <TextField
                                    select
                                    label={t('react.survey.conditions.popupcondition.apply.to')}
                                    value={conditionedUuid}
                                    onChange={handleChangeConditionedUuid}
                                >
                                    <MenuItem value={currentBlock.uuid}>{t('react.survey.conditions.popupcondition.apply.to.block.display')}</MenuItem>
                                    <MenuItem disabled={true}><Divider style={{width: '100%'}}/></MenuItem>
                                    <MenuItem disabled={true}>{t('react.survey.conditions.popupcondition.apply.to.item.display')}</MenuItem>
                                    {currentBlock.configuration.battery.items.map((z, index) => (
                                        <MenuItem value={z.uuid} key={index}>{stripHtmlTags(z.text)}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        }
                        {renderConditionner(currentCondition)}
                    </div>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={callBackCancelConditions}>{t('react.button.cancel')}</Button>
                <Button color="primary" onClick={handleConfirm}>{t('react.button.save')}</Button>
            </DialogActions>
        </Dialog>
    );
}
