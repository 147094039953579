import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import CustomNextButton from './CustomNextButton';
import { CollectorContext } from './context';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const useStyles = makeStyles()(theme => ({
    textAndButton: {
        position: 'relative',
    },
    positionRelative: {
        position: 'relative',
        paddingBottom: 50
    },
    textTempo: {
        textAlign: 'center',
    },
    imgHotspot: {
        textAlign: 'center',
        verticalAlign: 'middle',
        border: '1px solid rgb(100,100,100)',
        /* avoid double click selection */
        userSelect: 'none',  /* standard syntax */
        WebkitUserSelect: 'none',  /* webkit (safari, chrome) browsers */
        MozUserSelect: 'none',     /* mozilla browsers */
        KhtmlUserSelect: 'none',   /* webkit (konqueror) browsers */
        msUserSelect: 'none'       /* IE10+ */
    },
    buttonNext: {
        marginBottom: 50
    },
    textHelperZones: {
        color: 'grey', 
        size: 10, 
        fontStyle: 'italic',
        marginTop: 10
    }
}));

export default function HotSpot(props) {

    const { classes } = useStyles();

    const {
        t, showSpinner, openSnackbar, block
    } = props;

    const {
        collectParameter, collectorsService, handleNextBlock, embeddedReplaceDynamicElements,
        handleScreenOut, imagesOfForm, participantId, handleQuotaFull, userHistory
    } = useContext(CollectorContext);

    const [hotspotImage, setHotspotImage] = useState(undefined);
    const [polygons, setPolygons] = useState([]);
    const [answersUser, setAnswersUser] = useState([]);
    const [possibleAnswers, setPossibleAnswers] = useState([]);
    const [widthImg, setWidthImg] = useState(0);
    const [isHovered, setIsHovered] = useState([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState(false);
    const [hasGroupsOfZones, setHasGroupsOfZones] = useState(false);
    const [realMinAnswers, setRealMinAnswers] = useState(null);
    const [realMaxAnswers, setRealMaxAnswers] = useState(null);

    const [dataReady, setDataReady] = useState(false);
    const [imageReady, setImageReady] = useState(false);

    useEffect(() => {
        setDataReady(false);
        if (!block) return;

        // always reset all fields when a new bloc comes
        setAnswersUser([]);
        setIsHovered([]);
        setSelectedCheckbox(false);
        setPolygons(block.hotspot.zones);
        setPossibleAnswers(block.hotspot.answers);
        setWidthImg(block.hotspot.displayWidth);
        setHasGroupsOfZones(!block.hotspot.zones.every(z => z.groupId === 0));

        // If minExpectedAnswers > total number of zones, we have to change it
        setRealMinAnswers(block.hotspot.nbMinExpectedAnswers > block.hotspot.zones.length ? block.hotspot.zones.length : block.hotspot.nbMinExpectedAnswers);
        setRealMaxAnswers(block.hotspot.nbMaxExpectedAnswers > block.hotspot.zones.length ? block.hotspot.zones.length : block.hotspot.nbMaxExpectedAnswers);

        setDataReady(true);
    }, [block]);

    /*
     * This useEffect() is only used to control the display of the image
     */
    useEffect(() => {
        setImageReady(false);
        if (!block) return;

        // image is mandatory
        if (block.imageId === 0) {
            setHotspotImage(undefined);
            setImageReady(false);
            handleScreenOut();
            return;
        }

        let img = imagesOfForm.get(block.imageId);
        if (img !== undefined) {
            setHotspotImage(img);
            setImageReady(true);
            return;
        }

        showSpinner(true);
        collectorsService.getPublicFormFileUrl(collectParameter, block.imageId)
            .then(result => {
                imagesOfForm.set(block.imageId, result.url);
                setHotspotImage(result.url);
                setImageReady(true);
                showSpinner(false);
            }).catch(e => {
                setHotspotImage(undefined);
                setImageReady(false);
                showSpinner(false);
                openSnackbar('error', t('react.error.fetch.message'));
                handleScreenOut();
            });
    }, [block]);

    function getHighestPointsOfEachPolygons(str) {
        const pairs = str.split(' ').map(pair => pair.split(','));
      
        const minYValuePair = pairs.reduce((min, current) => {
          const currentY = Number(current[1]);
          if (!isNaN(currentY) && currentY < min[0]) {
            return [currentY, Number(current[0])];
          }
          return min;
        }, [Infinity, Infinity]);
      
        return minYValuePair;
    }

    const handleClickPolygon = (event, p) => {
        event.preventDefault();
        setSelectedCheckbox(false);

        let tempArr = [...answersUser];
        var indexIfSelected = tempArr.findIndex(z => z.zoneId === p.id);

        if (indexIfSelected === -1) {
            // zone is not already selected
            if (block.hotspot.type === 'single') {
                tempArr = [];
            }

            if (hasGroupsOfZones) {
                block.hotspot.zones
                    .filter(z => z.groupId === p.groupId)
                    .forEach(poly => {
                        tempArr.push({ zoneId: poly.id, answerId: possibleAnswers[0].id });
                    });
            } else {
                tempArr.push({ zoneId: p.id, answerId: possibleAnswers[0].id });
            }
        } else {
            let indexOfAnswer = possibleAnswers.findIndex(a => a.id === tempArr[indexIfSelected].answerId);
            if (indexOfAnswer === possibleAnswers.length - 1) {
                // it's the last answer so we have to remove from selected
                if (hasGroupsOfZones) {
                    block.hotspot.zones
                        .filter(z => z.groupId === p.groupId)
                        .forEach(poly => {
                            // remove all answers having the same groupId
                            tempArr = tempArr.filter(a => a.zoneId !== poly.id);
                        });
                } else {
                    tempArr = tempArr.filter(a => a.zoneId !== p.id);
                }
            } else {
                if (hasGroupsOfZones) {
                    block.hotspot.zones
                        .filter(z => z.groupId === p.groupId)
                        .forEach(poly => {
                            let correctIndex = tempArr.findIndex(i => i.zoneId === poly.id);
                            tempArr[correctIndex].answerId = possibleAnswers[indexOfAnswer + 1].id;
                        });
                } else {
                    tempArr[indexIfSelected].answerId = possibleAnswers[indexOfAnswer + 1].id;
                }
            }
        }

        setAnswersUser(tempArr);
    };

    const saveBlock = async () => {
        showSpinner(true);

        await collectorsService.collectHotSpotAnswer(participantId, block.id, answersUser)
            .then(response => {
                setDataReady(false);
                showSpinner(false);
                pushAnswersToUserHistory();
                handleNextBlock();
            }).catch(error => {
                setDataReady(false);
                showSpinner(false);
                if (error.response.status === 302) {
                    handleQuotaFull();
                } else if (error.response.status === 307) {
                    handleScreenOut();
                } else {
                    openSnackbar('error', t('react.error.save.message'));
                    handleScreenOut();
                }
            });
    };

    const pushAnswersToUserHistory = () => {
        // append user answers of the hotspot to the user answers list for text replacement
        const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id);
        answersUser.forEach(it => {
            const objectRef = `${block.ref}.${block.hotspot.zones.find(z => z.id === it.zoneId).ref}`;
            const answer = block.hotspot.answers.find(a => a.id === it.answerId);
            const answers = [{ id: answer.id, text: answer.name, freeField: false }];
            const userAnswerToAdd = { objectRef: objectRef, answers: answers, enteredValue: undefined };
            userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);
        });
    };
 
    const getPolygonColor = (polygon) => {
        var indexIfSelected = answersUser.findIndex(a => a.zoneId === polygon.id);
        if(indexIfSelected >= 0) {
            // returns the color of the answer if selected
            return possibleAnswers.find(a => a.id === answersUser[indexIfSelected].answerId).color;
        }

        // else if the cursor is hover the polygon (or over another of the same group if any)
        // returns the color of the polygon
        if (isHovered.includes(polygon.id)) {
            return polygon.color;
        }

        // returns the default no-color
        return "rgba(0,0,0,0)";
    };

    const handleMouseOver = (polygon) => {
        setIsHovered(
            hasGroupsOfZones ?
                block.hotspot.zones
                    .filter(z => z.groupId === polygon.groupId)
                    .map(z => z.id)
                : [polygon.id]);
    };

    const handleMouseOut = () => {
        setIsHovered([]);
    };

    /**
     * Check if the user can pass the block:
     */
    const canContinue = () => {
        // special case when the exclusive checkbox is available
        if(block.hotspot.displayExclusiveCheckbox) {
            // if the exclusive checkbox exists, the user can continue only if:
            // - the checkbox IS checked AND he has NO selection
            const isExclusivityOk = selectedCheckbox && answersUser.length === 0;
            // OR
            // - the checkbox IS NOT checked AND he has hasEnoughAnswers = true (at least 1)
            const hasEnoughAnswers = !selectedCheckbox && answersUser.length > 0 && answersUser.length >= realMinAnswers;

            return isExclusivityOk || hasEnoughAnswers;
        }

        // when no special case, return true if 0 answers are expected OR if the user has selected enough expected count
        return (realMinAnswers === 0 && realMaxAnswers === 0) || (answersUser.length >= realMinAnswers && answersUser.length <= realMaxAnswers)
    }

    const helperText = () => {
        var textToReturn = null;
        if (block.hotspot.type == 'multiple') {
            if (realMinAnswers > 0 && realMinAnswers === realMaxAnswers) {
                textToReturn = t('react.collectors.hotspot.mandatory.answers.help', {
                    number: realMinAnswers,
                });
            }
            if (realMinAnswers > 0 && realMaxAnswers < block.hotspot.zones.length) {
                textToReturn = t('react.collectors.hotspot.minimumAndMaximum.answers', {
                  numMinAnswers: realMinAnswers,
                  numMaxAnswers: realMaxAnswers,
                });
            }
            if (realMinAnswers > 0) {
                textToReturn = t('react.collectors.hotspot.minimum.answers.help', {
                    number: realMinAnswers,
                });
            }
            if (realMaxAnswers > 0 && realMaxAnswers < block.hotspot.zones.length) {
                textToReturn = t('react.collectors.hotspot.maximum.answers.help', {
                    number: realMaxAnswers,
                });
            }
        } else {
            textToReturn = t('react.collectors.hotspot.help.single');
        }
        return <div className={classes.textHelperZones}>{textToReturn}</div>
      };

    if(!dataReady || !imageReady) return null;

    return (
        <Container className={classes.positionRelative}>
            <Grid container spacing={4}>
                {hotspotImage !== undefined &&
                    <Grid item xs={12}>
                        {block.hotspot.question && <div className="ql-view" dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.hotspot.question) }} />}      
                        <div style={{width: widthImg, position: 'relative', display: "flex", justifyContent:'center', marginTop: 30}}>
                            <img src={hotspotImage} className={classes.imgHotspot} style={{ width: widthImg }}/>
                            <svg
                                width="100%"
                                height="100%"
                                style={{ position: 'absolute', top: 0, left: 0, outline: "none"}}
                            >
                                {polygons.map((polygon, index) => (
                                    <polygon
                                        key={index}
                                        points={polygon.coordinates}
                                        onMouseOver={() => handleMouseOver(polygon)}
                                        onMouseOut={handleMouseOut}
                                        fill={getPolygonColor(polygon)}
                                        onClick={(event) => handleClickPolygon(event,polygon)}
                                        style={{ stroke: polygon.color, 
                                            strokeWidth: `${polygon.strokeWidth}px`, 
                                            cursor: 'pointer',
                                        }}
                                    />
                                ))}
                            </svg>
                        </div>
                        <div className={classes.textHelperZones}>{helperText()}</div>
                    </Grid>
                }
            </Grid>
            {block.hotspot.displayExclusiveCheckbox && 
                <FormControlLabel 
                    style={{position: 'absolute'}}
                    control={<Checkbox disabled={answersUser.length > 0} style={{color: answersUser.length > 0 ? '#aaaaaa' : '#555555'}} checked={selectedCheckbox} onChange={() => setSelectedCheckbox(!selectedCheckbox)}/>} 
                    label={block.hotspot.exclusiveCheckboxText} 
                />
            }
            <CustomNextButton
                {...props}
                disabled={!canContinue()}
                handleNextBlock={saveBlock}
            />          
        </Container>
    )
};
