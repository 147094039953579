import React, { useEffect, useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitleWithCloseIcon from "../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon";
import { Button, FormControlLabel, Switch, Select, MenuItem, TextField, Typography } from "@mui/material";
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import { makeStyles } from "tss-react/mui";
import { Trans } from "react-i18next";

const useStyles = makeStyles()((theme) => ({
    titleIcon: {
        marginRight: "12px",
        verticalAlign: "sub",
    },
    formControlLabel: {
        marginLeft: "0px",
        marginTop: "0px",
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        width: "550px",
    },
    flexBetweenMinAnswers: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 10,
    },
    widthInput: {
        width: "60px",
    },
    widthDescription: {
        width: "100%",
    },
    errorText: {
        color: "red",
        whiteSpace: "pre-wrap",
        marginTop: "10px",
    },
    flex: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "20px",
    },
    textFieldEditAnswer: {
        color: "grey",
        fontSize: "small",
        marginTop: "10px",
    }
}));

export default function FireSimulationsDialog(props) {
    const { classes } = useStyles();

    const { t, openState, callbackOnclose, handleClickFireSimulations } = props;

    const [onlyQualifiedAnswers, setOnlyQualifiedAnswers] = useState(false);
    const [nbSimulations, setNbSimulations] = useState(50);
    const [customAnswers, setCustomAnswers] = useState("");
    const [errorMessages, setErrorMessages] = useState("");

    const validateSyntax = useCallback((input) => {
        const lines = input.split("\n");
        const result = {};
        const errors = [];
        const regex = /^(Q\d+(\.[AIZ]\d+)?):\s*(.*)$/;

        lines.forEach((line, index) => {
            const trimmedLine = line.trim();
            if (trimmedLine === "") return;

            const match = trimmedLine.match(regex);
            if (match) {
                const key = match[1];
                const value = match[3];
                result[key] = value;
            } else {
                errors.push(`${t("react.surveys.battery.selectMode.row")} ${index + 1}: "${line}" ${t("react.project.collectforms.toolbar.simulations.popup.syntax.error")}`);
            }
        });

        if (errors.length > 0) {
            setErrorMessages(errors.join("\n"));
            return null;
        } else {
            setErrorMessages("");
            return result;
        }
    }, []);

    useEffect(() => {
        if (!openState) return;
        setOnlyQualifiedAnswers(false);
        setNbSimulations(50);
        setCustomAnswers("");
        setErrorMessages("");
    }, [openState]);

    useEffect(() => {
        validateSyntax(customAnswers);
    }, [customAnswers, validateSyntax]);

    const handleSubmit = () => {
        const parsedAnswers = validateSyntax(customAnswers);
        if (parsedAnswers) {
            const jsonResult = {
                customAnswers: parsedAnswers,
                onlyQualifiedAnswers: onlyQualifiedAnswers,
                nbSimulations: parseInt(nbSimulations, 10),
            };

            handleClickFireSimulations(jsonResult);
            callbackOnclose();
        }
    };

    if (!openState) return null;

    return (
        <Dialog open={openState} onClose={callbackOnclose}>
            <DialogTitleWithCloseIcon
                startIcon={<MovieFilterIcon color="secondary" className={classes.titleIcon} />}
                title={t("react.project.collectforms.toolbar.simulations.popup.title")}
                callbackOnclose={callbackOnclose}
            />
            <DialogContent className={classes.dialogContent}>
                <div className={classes.flexBetweenMinAnswers}>
                    <Typography>
                        {t("react.button.collectforms.toolbar.simulations.popup.qualifiedData")}
                    </Typography>
                    <FormControlLabel
                        control={<Switch checked={onlyQualifiedAnswers} color="secondary" onChange={() => setOnlyQualifiedAnswers(!onlyQualifiedAnswers)} />}
                        labelPlacement="start"
                    />
                </div>
                <div className={classes.flex}>
                    <Select
                        className={classes.widthInput}
                        value={nbSimulations}
                        onChange={(e) => setNbSimulations(e.target.value)}
                        label={t("react.button.collectforms.toolbar.simulations.popup.number")}
                    >
                        {[50, 100, 150, 200, 250, 300, 350, 400, 450, 500].map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography>{t("react.button.collectforms.toolbar.simulations.popup.nbsimulations")}</Typography>
                </div>
                <div>
                    <TextField
                        className={classes.widthDescription}
                        margin="dense"
                        id="custom-answers"
                        label={t("react.button.collectforms.toolbar.simulations.popup.custom.answers")}
                        variant="outlined"
                        multiline={true}
                        maxRows={5}
                        minRows={5}
                        type="text"
                        value={customAnswers}
                        autoComplete="off"
                        onChange={(e) => setCustomAnswers(e.target.value)}
                        inputProps={{ maxLength: 1024 }}
                    />
                    {errorMessages && <Typography variant="body2" className={classes.errorText}>{errorMessages}</Typography>}
                </div>
                <div>
                    <Typography variant="body2" className={classes.textFieldEditAnswer}>
                        <Trans i18nKey={"react.button.collectforms.toolbar.simulations.popup.custom.answers.help"} />
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={callbackOnclose} color="primary">
                    {t("react.button.cancel")}
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    disabled={errorMessages !== ""}
                >
                    {t("react.project.collectforms.download.links.generate")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
