import React, { useEffect, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import Image from './Image';
import CustomNextButton from './CustomNextButton';
import { isBlank } from '../../utils/utils';
import { CollectorContext } from './context';

const useStyles = makeStyles()(theme => ({
    positionRelative: {
        position: 'relative'
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
    }
}));

export default function OpenQuestion(props) {

    const { classes } = useStyles();

    const { 
        t, showSpinner, openSnackbar, block
    } = props;

    const { 
        collectParameter, collectorsService, handleNextBlock, embeddedReplaceDynamicElements,
        handleScreenOut, participantId, imagesOfForm, handleQuotaFull, userHistory
    } = useContext(CollectorContext);

    const [answer, setAnswer] = useState(Array(block.openQuestion.nbTextFields).fill(''));

    const [openQuestionImage, setOpenQuestionImage] = useState(undefined);

    const [dataReady, setDataReady] = useState(false);
    const [imageReady, setImageReady] = useState(false);

    useEffect(() => {
        setDataReady(false);
        if (!block) return;

        // always reset all fields when a new bloc comes
        setAnswer(Array(block.openQuestion.nbTextFields).fill(''));

        setDataReady(true);
    }, [block])

    /*
     * This useEffect() is only used to control the display of the image
     */
    useEffect(() => {
        setImageReady(false);

        if (!block || block.imageId === 0) {
            setOpenQuestionImage(undefined);
            setImageReady(true);
            return;
        }

        let img = imagesOfForm.get(block.imageId);
        if(img !== undefined) {
            setOpenQuestionImage(img);
            setImageReady(true);
            return;
        }

        showSpinner(true);

        collectorsService.getPublicFormFileUrl(collectParameter, block.imageId)
            .then(result => {
                imagesOfForm.set(block.imageId, result.url);
                setOpenQuestionImage(result.url);
                setImageReady(true);
                showSpinner(false);
            }).catch(e => {
                setOpenQuestionImage(undefined);
                setImageReady(false);
                showSpinner(false);
                openSnackbar('error', t('react.error.fetch.message'));
                handleScreenOut();
            });
    }, [block]);

    const saveBlock = async () => {
        showSpinner(true);

        await collectorsService.collectOpenQuestionAnswer(participantId, block.id, answer.join(' '))
            .then(response => {
                setDataReady(false);
                showSpinner(false);
                pushAnswersToUserHistory();
                handleNextBlock();
            }).catch(error => {
                setDataReady(false);
                showSpinner(false);
                if (error.response.status === 302) {
                    handleQuotaFull();
                } else if (error.response.status === 307) {
                    handleScreenOut();
                } else {
                    openSnackbar('error', t('react.error.save.message'));
                    handleScreenOut();
                }
            });
    };

    const pushAnswersToUserHistory = () => {
        // append user answers of the openQuestion to the user answers list for text replacement
        const answers = [{ id: 0, text: answer.join(' '), freeField: false }];
        const userAnswerToAdd = { objectRef: block.ref, answers: answers, enteredValue: undefined };
        const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id);
        userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);
    };

    const getTextFieldsAnswer = (index, text) => {
        let temporaryArray = [...answer]
        temporaryArray[index] = text
        setAnswer(temporaryArray)
    };

    const hasMinimumNumberOfCharacters = () => {
        // no minimum required
        if(block.openQuestion.minimumNumberOfCharacters <= 0) {
            return true;
        }

        // loop over all blocks to accumulate sizes
        let numberOfCharacters = answer.map(a => a.length).reduce((acc, cur) => acc + cur);
        return numberOfCharacters >= block.openQuestion.minimumNumberOfCharacters;
    }

    const getLabel = (index) => {
        if(block.nbTextFields === 1) {
          return t('react.collectform.openquestion.answer');
        } else if (index === 0) {
            return t('react.collectform.openquestion.answer.first');
        } else if (index === 1) {
            return t('react.collectform.openquestion.answer.second');
        } else if (index === 2) {
            return t('react.collectform.openquestion.answer.third');
        } else if (index === 3) {
            return t('react.collectform.openquestion.answer.fourth');
        } else if (index === 4) {
            return t('react.collectform.openquestion.answer.fifth');
        }
    };

    if(!dataReady || !imageReady) return null;

    return (
        <Container className={classes.positionRelative}>
            <Grid container spacing={4}>
                {openQuestionImage !== undefined &&
                    <Grid item xs={12}>
                        <Image
                            imageUrl={openQuestionImage}
                            position={block.imagePosition}
                            width={block.imageWidth}
                            openFullscreen={block.openImageFullscreen}
                        />
                    </Grid>
                }
                <Grid item sm={12} md={block.image !== undefined ? 8 : 12}>
                    {block.openQuestion.question && <div className="ql-view" dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.openQuestion.question) }} />}
                    {answer.map((answer, index) =>
                        <TextField
                            autoFocus={index === 0}
                            value={answer}
                            onChangeCapture={(e) => getTextFieldsAnswer(index, e.target.value)}
                            inputProps={{ maxLength: '1024' }}
                            required={index === 0 ? true : false}
                            multiline
                            minRows={block.openQuestion.nbTextFieldRows}
                            margin="dense"
                            label={getLabel(index)}
                            autoComplete='off'
                            style={{ marginTop: "30px" }}
                            type='text'
                            fullWidth
                            variant='outlined'
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.input,
                                }
                            }}
                            error={block.openQuestion.minimumNumberOfCharacters > 0 && index === 0 && isBlank(answer)}
                            key={index}
                            helperText={block.openQuestion.minimumNumberOfCharacters > 0 && index === 0 && t("react.collectform.openquestion.minimum.characters", { number: block.openQuestion.minimumNumberOfCharacters })}
                        />
                    )}
                </Grid>
            </Grid>
            <CustomNextButton
                {...props}
                disabled={!hasMinimumNumberOfCharacters()}
                handleNextBlock={saveBlock}
            />
        </Container>
    )
};
