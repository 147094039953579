import React, { useContext, useEffect, useState } from 'react';
import { CollectorContext, CollectorTestContext } from './context';
import Button from '@mui/material/Button';
import { cloneDeep, isBlank, appendSuffixIfGreaterThan } from '../../utils/utils';
import Divider from '@mui/material/Divider';
import { Tooltip, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getBlockIcon } from '../../utils/surveysUtils';
import _ from 'lodash';
import ReplayIcon from '@mui/icons-material/Replay';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Grid from '@mui/material/Grid';


export default function CollectorsTestPanel(props) {

    const { t, showSpinner, block, setActualBlock } = props;

    const {
        collectorsService, handleNextBlock, handleScreenOut, blocksToDisplay,
        actualBlock, participantId, handleQuotaFull, userHistory
    } = useContext(CollectorContext);

    const {
        survey
    } = useContext(CollectorTestContext);

    const [originalBlocksToDisplay, setOriginalBlocksToDisplay] = useState(undefined);

    useEffect(() => {
        // only keep a copy of the original load
        if(blocksToDisplay === undefined || originalBlocksToDisplay !== undefined) return;

        setOriginalBlocksToDisplay(cloneDeep(blocksToDisplay));
        
    }, [blocksToDisplay /* everytime the blocksToDisplay change */]);

    const isLastBlock = () => {
      return blocksToDisplay[blocksToDisplay.length-1].id === block.id;
    };

    const handleRestartSurvey = () => {
        window.location.reload();
    };

    const findAnsweredQuestions = (userHistoryEntry) => {
        // if current block
        if (userHistoryEntry.blockId === block.id) return;

        var answerToReturn = '';
        const blockToWork = blocksToDisplay.find(b => b.id === userHistoryEntry.blockId);

        if (blockToWork.type === "introduction") {
            answerToReturn = t('react.project.collectforms.block.introduction.title');
        } else if (blockToWork.type === "text") {
            answerToReturn = t('react.project.collectforms.block.text.title');
        } else if (blockToWork.type === "openQuestion") {
            // @see OpenQuestion.js pushAnswersToUserHistory()
            answerToReturn = userHistoryEntry.elements.map(abqr => abqr.answers).flat().map(a => a.text).join(' / ');
        } else if (blockToWork.type === "experience") {
            // @see QuestionR3m.js pushAnswersToUserHistory()
            answerToReturn = userHistoryEntry.elements.map(abqr => abqr.answers).flat().map(a => a.text).join(', ');
        } else if (blockToWork.type === "question") {
            // @see ProfileQuestion.js pushAnswersToUserHistory()
            answerToReturn = userHistoryEntry.elements.map(abqr => abqr.answers)
                .flat()
                .map(a => a.text)
                .join(' / ');
            let enteredValue = userHistoryEntry.elements.map(e => e.enteredValue /* only one normaly */ ).join(', ');
            if (!isBlank(enteredValue)) {
                answerToReturn += ` / entered value = ${enteredValue}`;
            }
        } else if (blockToWork.type === "battery") {
            // @see BatteryOfItems.js pushAnswersToUserHistory()
            answerToReturn = blockToWork.battery.items.map(item => {
                    const userAnswer = userHistoryEntry.elements.find(abqr => abqr.objectRef === `${blockToWork.ref}.${item.ref}`);
                    if (userAnswer) {
                        let text = `${item.ref}: ${userAnswer.answers.map(a => a.text /* only one normaly */).join(' / ')}`;
                        if (!isBlank(userAnswer.enteredValue)) {
                            text += ` / entered value = ${userAnswer.enteredValue}`;
                        }
                        return text;
                    } else {
                        return `${item.ref}: -`;
                    };
                })
                .join(' / ');
        } else if (blockToWork.type === "hotspot") {
            // @see HotSpot.js pushAnswersToUserHistory()
            answerToReturn = blockToWork.hotspot.zones.map(zone => {
                    const userAnswer = userHistoryEntry.elements.find(abqr => abqr.objectRef === `${blockToWork.ref}.${zone.ref}`);
                    if (userAnswer) {
                        return `${zone.ref}: ${userAnswer.answers.map(a => a.text /* only one normaly */).join(' / ')}`;
                    } else {
                        return `${zone.ref}: -`;
                    };
                })
                .join(' / ');
        }

        return `${blockToWork.ref}: ${!isBlank(answerToReturn) ? answerToReturn : '-'}`;
    };

    const automaticAnswer = async () => {
        showSpinner(true);

        const handleResponse = () => {
          showSpinner(false);
          handleNextBlock();
        };
      
        const handleError = (error) => {
          showSpinner(false);
          if (error.response.status === 302) {
            handleQuotaFull();
          } else if (error.response.status === 307) {
            handleScreenOut();
          } else {
            openSnackbar('error', t('react.error.save.message'));
            handleScreenOut();
          }
        };

        if (block.type === "text" || block.type === "introduction") {
            showSpinner(false);
            handleNextBlock();
        } else if (block.type === "question") {
            // @see ProfileQuestion.js pushAnswersToUserHistory()
            let enteredValue = undefined;

            var numberOfElements = block.profile.type === "multiple"
                ? _.random(0, block.profile.answers.length)
                : 1;

            // append user answers of the question to the user answers list for text replacement
            const userResponse = [];
            const answers = _.shuffle(block.profile.answers)
                .slice(0, numberOfElements)
                .map(answer => {
                    userResponse.push(answer.id);

                    if(answer.freeField) {
                        enteredValue = 'lorem ipsum';
                    }
                    return {
                            id: answer.id,
                            text: answer.answer,
                            freeField: answer.freeField
                        };
                });
            const userAnswerToAdd = { objectRef: block.ref, answers: answers, enteredValue:  enteredValue};
            const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id);
            userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);

            await collectorsService.collectQuestionsAnswer(participantId, block.id, userResponse, enteredValue)
                .then(handleResponse)
                .catch(handleError);
  
        } else if (block.type === "experience") {
            // @see QuestionR3m.js pushAnswersToUserHistory()
            const answers = [
                {id: undefined, text: 'firstAnswer', freeField: undefined},
                {id: undefined, text: 'secondAnswer', freeField: undefined},
                {id: undefined, text: 'thirdAnswer', freeField: undefined}
            ];
            const userAnswerToAdd = { objectRef: block.ref, answers: answers, enteredValue: undefined };
            const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id);
            userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);

            await collectorsService.collectExperienceAnswer(participantId, block.id, block.rankInSequence, ['firstAnswer', 'secondAnswer', 'thirdAnswer'])
                .then(handleResponse)
                .catch(handleError);
        } else if (block.type === "battery") {
            // @see BatteryOfItems.js pushAnswersToUserHistory()
            const userResponse = [];

            // append user answers of the hotspot to the user answers list for text replacement
            const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id);

            block.battery.items.forEach(item => {
                let enteredValue = undefined;
                const userResponseAnswerIds = [];

                var numberOfAnswers = block.battery.type === "multiple"
                ? _.random(0, block.battery.answers.length)
                : 1;

                // append user answers of the question to the user answers list for text replacement
                const answers = _.shuffle(block.battery.answers)
                    .slice(0, numberOfAnswers)
                    .map(answer => {
                        userResponseAnswerIds.push(answer.id);
                        if(answer.freeField) {
                            enteredValue = 'lorem ipsum';
                        }
                        return {
                            id: answer.id,
                            text: answer.text,
                            freeField: answer.freeField
                        };
                    });

                const userAnswerToAdd = { objectRef: `${block.ref}.${item.ref}`, answers: answers, enteredValue: enteredValue };
                userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);

                userResponse.push({itemId: item.id, answerIds: userResponseAnswerIds, enteredValue: enteredValue});
            });
  
            await collectorsService.collectBatteryOfItemsAnswer(participantId, block.id, userResponse)
                .then(handleResponse)
                .catch(handleError);
        } else if (block.type === "hotspot") {
            // @see HotSpot.js pushAnswersToUserHistory()
            var numberOfElements = block.hotspot.type === "multiple"
                ? _.random(0, block.hotspot.zones.length)
                : 1;

            const randomizedSelectedZones = _.shuffle(block.hotspot.zones)
                .slice(0, numberOfElements);

            // append user answers of the hotspot to the user answers list for text replacement
            const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id);

            const userResponse = [];
            randomizedSelectedZones.forEach(zone => {
                // select 1 random answer in the list for this zone
                const answers = _.shuffle(block.hotspot.answers)
                    .slice(0, 1)
                    .map(answer => {
                        userResponse.push({'zoneId': zone.id,'answerId': answer.id});

                        return {
                            id: answer.id,
                            text: answer.name,
                            freeField: false
                        };
                    });

                const userAnswerToAdd = { objectRef: `${block.ref}.${zone.ref}`, answers: answers };
                userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);
            });

            await collectorsService.collectHotSpotAnswer(participantId, block.id, userResponse)
                .then(handleResponse)
                .catch(handleError);
        } else if (block.type === "openQuestion") {
            // @see OpenQuestion.js pushAnswersToUserHistory()
            const text = 'lorem ipsum';
            const answers = [
                {id: undefined, text: text, freeField: undefined}
            ];
            const userAnswerToAdd = { objectRef: block.ref, answers: answers, enteredValue: undefined };
            const blockHistoryIdx = userHistory.findIndex(it => it.blockId === block.id);
            userHistory[blockHistoryIdx].elements.push(userAnswerToAdd);
            //FIXME deal with the minimum text size

            await collectorsService.collectOpenQuestionAnswer(participantId, block.id, text)
                .then(handleResponse)
                .catch(handleError);
        };
    };

    const getBlockTitle = (blockId) => {
        var title = undefined;
        var ablock = survey.blocks.find(s => s.id === blockId);
        if(!ablock) return 'SCREENOUT / QUOTAFULL'; /* it can append when create a screenout or quotafull fake block */
        switch(ablock.type) {
            case 'introduction':
            case 'thankyou':
                title = ablock.ref;
                break;
            case 'question':
                title = `${ablock.ref}: ${ablock.question.filter}`;
                break;
            case 'openQuestion':
                title = `${ablock.ref}: ${ablock.openQuestion.stimulusName}`;
                break;
            case 'experience':
                title = `${ablock.ref}: ${ablock.experience.stimulusName}`;
                break;
            case 'hotspot':
            case 'battery':
            case 'text':
                    title = `${ablock.ref}: ${ablock.title}`;
                break;
        } 

        return title;
    };

    return (
        <Grid container spacing={2} style={{ position: 'relative', marginTop: 60 }}>
            <Grid item xs={12}>
                <Divider style={{ marginTop: 20 }} />
            </Grid>
            <Grid item xs={2}>
                <Button style={{ height: 48 }} variant="outlined" onClick={handleRestartSurvey} startIcon={<ReplayIcon />}>{t('react.collectors.testmode.restart')}</Button>
            </Grid>
            <Grid item xs={4}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>{t('react.collectors.testmode.navigation')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: 280, maxHeight: 450, overflowY: 'auto' }}>
                        {blocksToDisplay.map((block, index) => {
                            var title = getBlockTitle(block.id);
                            return (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ width: 20 }}>{index + 1}</Typography>
                                <Tooltip title={title} placement="right">
                                <Button
                                    variant={index === actualBlock ? "contained" : "outlined"}
                                    onClick={() => setActualBlock(index)}
                                    style={{ margin: 5, width: 240 }}
                                    startIcon={getBlockIcon(block)}
                                    disabled={isLastBlock()}
                                    sx={{
                                        width: "100%",
                                        "& .MuiButton-endIcon": {
                                            position: "absolute",
                                            right: "1rem",
                                        },
                                        "& .MuiButton-startIcon": {
                                            position: "absolute",
                                            left: "1rem",
                                        },
                                    }}
                                >
                                    {appendSuffixIfGreaterThan(title, '...', 15)}
                                </Button>
                                </Tooltip>
                            </div>
                        )})}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={4}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                    >
                        <Typography>{t('react.collectors.testmode.path.traveled')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column', width: 440, maxHeight: 450, overflowY: 'auto', fontSize: '11px' }}>
                        {userHistory.map((userHistoryEntry, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                <div className="ql-view" dangerouslySetInnerHTML={{ __html: findAnsweredQuestions(userHistoryEntry) }} />
                            </div>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={2}>
                <Button style={{ height: 48 }} disabled={isLastBlock()} variant="outlined" onClick={automaticAnswer} startIcon={<AutoFixHighIcon />} >{t('react.collectors.testmode.automatic.answer')}</Button>
            </Grid>
        </Grid>
  );
}
